<template>
  <Adm v-if="isAdmin" :videcam="videcam" @on-watch="onWatch" />
</template>

<script>
import Adm from './adm.vue'
import { mapGetters } from 'vuex'
import { GETTER_APP } from '@/store/const/type'
import { getAxiosAdmVidecam, convertVidecam, patchAxiosVidecam } from './func'
export default {
  name: 'index-videcam',
  components: { Adm },
  data() {
    return {
      videcam: {},
      internal: 0
    }
  },
  methods: {
    async onWatch(e) {
      const { id, watch } = await patchAxiosVidecam(e)
      this.videcam[id].watch = watch
      this.videcam[id].downtime = Date.now()
    }
  },
  computed: {
    ...mapGetters({ isAdmin: GETTER_APP.IS_ADMIN })
  },
  watch: {
    async isAdmin() {
      if (this.isAdmin) {
        const item = await getAxiosAdmVidecam()
        this.videcam = convertVidecam(item)
      }
    }
  },
  async created() {
    this.internal = setInterval(() => {
      getAxiosAdmVidecam().then((e) => {
        this.videcam = convertVidecam(e)
      })
    }, 1000 * 60)
    if (this.isAdmin) {
      const item = await getAxiosAdmVidecam()
      this.videcam = convertVidecam(item)
    }
  },
  destroyed() {
    clearInterval(this.internal)
  }
}
</script>
