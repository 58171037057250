import { store } from '@/store'
import axios from 'axios'
import { API_URL } from '@/const'

export const patchAxiosVidecam = async ({ id, watch }) => {
  const { accessToken } = store.state.app.user
  const headers = {
    Authorization: `Bearer ${accessToken}`
  }
  try {
    const { data } = await axios.patch(`${API_URL.VIDECAM_V1_WATCH}/${id}`, { watch: !watch }, { headers })
    return data
  } catch (e) {
    console.log(e.response?.data)
  }
  return {}
}
