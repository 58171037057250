<template>
  <button
    class="adm-btn-ctl"
    :style="{ background: color }"
    @click="$emit('on-watch', { id, watch: isWatch })"
  >{{ title }}
  </button>
</template>

<script>
export default {
  name: '',
  props: {
    id: { type: String },
    isWatch: { type: Boolean }
  },
  components: {},
  data() {
    return {}
  },
  methods: {},
  computed: {
    color() {
      return this.isWatch ? 'red' : 'green'
    },
    title() {
      return this.isWatch ? 'стоп' : 'старт'
    }
  },
  created() {
  }
}
</script>

<style lang="scss">
@import './styles';
</style>
