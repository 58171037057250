export const getTime = (e) => {
  const duration = Math.round((Date.now() - e) / 1000)
  if (duration <= 120) {
    return ''
  }
  if (duration > 120 && duration <= 3600) {
    const min = Math.round(duration / 60)
    return `${min} мин.`
  }
  if (duration > 3600 && duration <= 86400) {
    const hour = Math.round(duration / 3600)
    return `${hour} ч.`
  }
  if (duration > 86400 && duration <= 2592000) {
    const day = Math.round(duration / 86400)
    return `${day} д.`
  }
  if (duration > 2592000 && duration <= 31536000) {
    const day = Math.round(duration / 2592000)
    return `${day} м.`
  }
  if (duration > 31536000) {
    return `больше года`
  }
  return e
}
