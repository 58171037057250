<template>
  <div class="adm-report-status" :style="{ background: color }" />
</template>

<script>
export default {
  name: 'status',
  props: {
    isAvailable: { type: Boolean }
  },
  components: {},
  data() {
    return {}
  },
  methods: {},
  computed: {
    color() {
      return this.isAvailable ? 'green' : 'red'
    }
  },
  created() {}
}
</script>

<style lang="scss">
@import './styles';
</style>
