import axios from 'axios'
import { API_URL } from '@/const'
import { store } from '@/store'

export const getAxiosAdmVidecam = async () => {
  const { accessToken } = store.state.app.user
  const headers = {
    Authorization: `Bearer ${accessToken}`
  }
  try {
    const { data } = await axios.get(API_URL.VIDECAM_V1_ADM, { headers })
    return data
  } catch (e) {
    console.log(e.response?.data)
  }
  return []
}
