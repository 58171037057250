<template>
  <div class="videcam-adm">
    <table class="table-vc-adm">
      <thead>
        <tr>
          <th v-for="(e, i) in header" :key="i">{{ e }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="v in videcam" :key="v.id">
          <td><Status :is-available="isStatus(v)" /></td>
          <td>{{ v.place }}</td>
          <td>{{ v.description }}</td>
          <td>{{ getTimeAdm(v.downtime) }}</td>
          <td><Btn :id="v.id" :is-watch="v.watch" @on-watch="$emit('on-watch', $event)" /></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Status from './comp/status/status'
import Btn from './comp/btn/btn.vue'
import { HEADER, getTime } from './func'
export default {
  name: 'videcam-adm',
  components: { Status, Btn },
  props: {
    videcam: { type: Object }
  },
  data() {
    return {
      header: HEADER
    }
  },
  methods: {
    isStatus(v) {
      return v.available && v.watch
    },
    getTimeAdm(e) {
      return getTime(e)
    }
  },
  computed: {},
  created() {}
}
</script>

<style lang="scss">
@import './styles';
</style>
